<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <router-link
          :to="{
            name: 'studentQuiz'
          }"
          class="btn btn-primary mb-3"
        >
          <i class="flaticon2-right-arrow"></i>
          {{ $t("GENERAL.BACK") }}
        </router-link>
        <KTPortlet :title="title">
          <template v-if="isBusy" v-slot:body>
            <div class="text-center text-danger my-2">
              <b-spinner variant="danger"></b-spinner><br />
              <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
            </div>
          </template>
          <template v-else v-slot:body>
            <b-row v-if="quiz_lists.length !== 0">
              <QuizView
                class="w-100"
                v-for="quiz_list in quiz_lists"
                :key="quiz_list"
                :id="quiz_list.id"
                :title="quiz_list.title"
                :date="quiz_list.date"
                :start_time="quiz_list.start_time"
                :end_time="quiz_list.end_time"
                :description="quiz_list.description"
                :type="quiz_list.type"
                :mark="quiz_list.mark"
                :feedback="quiz_list.feedback"
                :allow_submission="quiz_list.allow_submission"
                :due="quiz_list.due"
                :submitted_on="quiz_list.submitted_on"
                :lecturer_student_class_subject_id="
                  quiz_list.lecturer_student_class_subject_id
                "
                :class_id="class_id"
                :subject_id="subject_id"
              ></QuizView>
            </b-row>
            <b-row v-else>
              <b-alert variant="info" show class="text-justify text-left">{{
                $t("UPLOADED_QUIZ.NO_QUIZ_FOR_SEARCHED_CLASS")
              }}</b-alert>
            </b-row>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import QuizView from "../../partials/widgets/QuizView";

export default {
  name: "SubjectQuizList",
  data() {
    return {
      title: this.$t("MENU.QUIZ"),
      isBusy: true,
      class_id: null,
      subject_id: null,
      quiz_lists: []
    };
  },
  components: {
    KTPortlet,
    QuizView
  },
  methods: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.QUIZ"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get(
      "student/subject-quiz-list/" + this.class_id + "/" + this.subject_id
    ).then(({ data }) => {
      this.quiz_lists = data;
      this.isBusy = false;
    });
  },
  created() {
    if (
      this.$route.params.class_id !== null &&
      this.$route.params.class_id !== undefined &&
      this.$route.params.subject_id !== null &&
      this.$route.params.subject_id !== undefined
    ) {
      this.class_id = this.$route.params.class_id;
      this.subject_id = this.$route.params.subject_id;
    }
  }
};
</script>
