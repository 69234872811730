<template>
  <div :class="messageTypeClass">
    <div class="kt-chat__user" v-if="type === 'sent'">
      <span class="kt-media kt-media--circle kt-media--sm">
        <img :src="userPhoto" alt="image" />
      </span>
      <a href="#" class="kt-chat__username">{{ $t("GENERAL.YOU") }}</a>
      <span class="kt-chat__datetime">{{ time }}</span>
    </div>
    <div class="kt-chat__user" v-if="type === 'received'">
      <a href="#" class="kt-chat__username">{{ name }}</a>
      <span class="kt-chat__datetime">{{ time }}</span>
      <span class="kt-media kt-media--circle kt-media--sm">
        <img :src="image" alt="image" />
      </span>
    </div>
    <div :class="messageTextClass">{{ message }}</div>
    <b-button
      v-if="parseInt(status) === 0 && type === 'sent'"
      pill
      size="sm"
      variant="error"
      v-b-tooltip.hover
      :title="$t('GENERAL.DELETE_MESSAGE')"
      @click="deleteMessage(id)"
    >
      <i class="fa fa-trash"></i>
    </b-button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getUserPhoto } from "@/common/jwt.service";
import { DELETE_MESSAGE } from "@/store/chat.module";

export default {
  name: "MessageItem",
  props: ["id", "type", "image", "name", "time", "message", "status"],
  data() {
    return {
      messageTypeClass: "kt-chat__message",
      messageTextClass: "kt-chat__text"
    };
  },
  mounted() {
    if (this.type === "received") {
      this.messageTypeClass += " kt-chat__message--right";
      this.messageTextClass += " kt-bg-light-success";
    } else {
      this.messageTextClass += " kt-bg-light-brand";
    }
  },
  methods: {
    deleteMessage(id) {
      this.$store.dispatch(DELETE_MESSAGE, id);
    }
  },
  computed: {
    ...mapState({
      isPhotoLoaded: state => state.auth.isPhotoLoaded
    }),
    userPhoto() {
      if (this.isPhotoLoaded) {
        return getUserPhoto();
      }
      return null;
    }
  }
};
</script>
