<template>
  <b-overlay :show="loading" rounded="sm">
    <KTPortlet>
      <template v-slot:body>
        <span v-html="content"></span>
      </template>
    </KTPortlet>
    <!--<VueDocPreview
      v-if="office_types.includes(type)"
      :value="path"
      type="office"
    />-->
    <div
      v-if="type === 'Youtube' || type === 'pdf' || type === 'mp4'"
      :style="`height: ${height}px`"
    >
      <iframe
        v-if="type === 'Youtube'"
        :src="'https://www.youtube.com/embed/' + path"
        height="100%"
        width="100%"
        style="border: 0;"
      ></iframe>
      <!--<iframe
        v-if="type === 'pdf'"
        :src="path"
        height="100%"
        width="100%"
        style="border: 0;"
      ></iframe>
      <video v-if="type === 'mp4'" controls>
        <source :src="path" type="video/mp4" />
      </video>-->
    </div>
    <b-img
      v-if="image_types.includes(type)"
      :src="path"
      fluid
      center
      rounded=""
    ></b-img>
    <b-row v-if="discussion === 'true'">
      <b-col>
        <div class="kt-chat" style="padding-top: 20px;">
          <b-overlay :show="discussion_loading" rounded="sm">
            <KTPortlet class="kt-portlet--head-lg kt-portlet--last">
              <template v-slot:body>
                <div class="kt-chat__input">
                  <div class="kt-chat__editor">
                    <b-textarea
                      style="height: 50px"
                      id="newDiscussionInput"
                      :placeholder="$t('GENERAL.TYPE_COMMENT_HERE') + '...'"
                    ></b-textarea>
                  </div>
                  <div class="kt-chat__toolbar">
                    <div class="kt_chat__actions">
                      <button
                        type="button"
                        id="kt_send"
                        class="btn btn-brand btn-md btn-upper btn-bold kt-chat__reply"
                        style="color: white;"
                        v-on:click="sendDiscussion()"
                      >
                        {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="kt-separator kt-portlet__separator"></div>
                <div class="kt-chat__messages">
                  <MessageItem
                    v-for="message in messages"
                    :key="message.id"
                    :id="message.id"
                    :type="message.type"
                    :time="message.time"
                    :name="message.user"
                    :image="message.image"
                    :message="message.message"
                    :status="message.status"
                  ></MessageItem>
                </div>
              </template>
            </KTPortlet>
          </b-overlay>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import VueDocPreview from "vue-doc-preview";
import MessageItem from "@/views/pages/chat/MessageItem";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      office_types: ["docx", "doc", "xlsx", "xls", "pptx", "ppt"],
      image_types: ["jpeg", "jpg", "png", "gif"],
      height: 85,
      loading: true,
      discussion_loading: true,
      content: null,
      messages: []
    };
  },
  props: {
    type: null,
    path: null,
    model: null,
    id: null,
    discussion: null
  },
  components: {
    KTPortlet,
    //VueDocPreview,
    MessageItem
  },
  methods: {
    setHeight: function() {
      const clientHeight = document.documentElement.clientHeight;
      this.height = (clientHeight * this.height) / 100;
    },
    sendDiscussion() {
      const message = document.getElementById("newDiscussionInput");
      if (message.value) {
        const submitButton = document.getElementById("kt_send");
        submitButton.classList.add(
          "disabled",
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );
        let payload = {
          file_id: this.id,
          message: message.value
        };
        ApiService.put("files/add-discussion", payload)
          .then(({ data }) => {
            message.value = null;
            this.messages.unshift(data);
            submitButton.classList.remove(
              "disabled",
              "kt-spinner",
              "kt-spinner--light",
              "kt-spinner--right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "disabled",
              "kt-spinner",
              "kt-spinner--light",
              "kt-spinner--right"
            );
          });
      }
    }
  },
  mounted: function() {
    ApiService.get("files/get-descriptions/" + this.model + "/" + this.id)
      .then(({ data }) => {
        this.loading = false;
        this.content = data;
        if (this.discussion === "true") {
          ApiService.get("files/get-discussions/" + this.id)
            .then(({ data }) => {
              this.discussion_loading = false;
              this.messages = data;
            })
            .catch(() => {
              this.discussion_loading = false;
            });
        }
      })
      .catch(() => {
        this.loading = false;
      });
    this.setHeight();
  }
};
</script>
